import { useEffect, useRef, useState } from "react";

import { Box } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { BlogCard } from "../../../components/common/blog-card/blog-card";
import { Container } from "../../../components/common/container/container";
import { LeftIcon, RightIcon, ViewIcon } from "../../../components/icons/index";
import { Heading } from "../../../components/ui/heading/heading";
import { ArticleSkeleton } from "../../../components/ui/skeletons/article-skeleton/article-skeleton";
import { useActions, useLang, useOnClickOutside, useSetSeoPage } from "../../../hooks";
import { BlogActions } from "../../../store/blog/blog.slice";
import { getArticleByLangAndTag, getArticlesSidebar } from "../../../store/blog/blog.thunks";
import { getBlogState } from "../../../store/selectors";
import { ROUTE } from "../../../utils/constants";
import { getBlogTagFromLocation } from "../../../utils/helpers";

import { style } from "./article.style";

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const Article = () => {
  useSetSeoPage();
  const dispatch = useDispatch();
  const lang = useLang();

  const { tag } = useParams();
  const navigate = useNavigate();

  const { article, sidebar } = useSelector(getBlogState);
  const {
    title,
    countOfViews,
    articleBody,
    createdDate,
    nextArticleTag,
    nextArticleTitle,
    previousArticleTag,
    previousArticleTitle
  } = article.content;

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "instant"
    });
  }, [tag]);

  const { t } = useTranslation();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const sidebarRef = useRef(null);

  useOnClickOutside(sidebarRef, () => setIsSidebarOpen(false));

  // Dispatch
  const { getArticleByLangAndTag, getArticlesSidebar } = useActions();

  useEffect(() => {
    if (lang !== article.localeCode || tag !== article.tag) {
      getArticleByLangAndTag({ localeCode: lang, tag });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, tag, article.localeCode, article.tag]);

  useEffect(() => {
    const loaded = Object.keys(article.content).length;

    if (loaded) {
      if (lang !== sidebar.localeCode || sidebar.articleTranslationId !== article.content?.id) {
        getArticlesSidebar({
          localeCode: lang,
          size: 2,
          articleTranslationId: article.content?.id
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article.content, lang, sidebar.localeCode, sidebar.articleTranslationId]);

  // Handling error
  useEffect(() => {
    if (article.isFetchError) {
      navigate(`/${lang}${ROUTE.APP.BLOG}/`);
      dispatch(BlogActions.clearFetchError());
      // console.log('fetch error!!!');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article.isFetchError]);

  useEffect(() => {
    const originalOverflow = window.getComputedStyle(document.body).overflow;

    if (isSidebarOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, [isSidebarOpen]);

  const handleSidebar = () => setIsSidebarOpen((prev) => !prev);

  const hasSidebar = sidebar.new?.length || sidebar.popular?.length;

  return (
    <Box sx={style.article} className="article">
      <Container>
        {article.isLoading || sidebar.isLoading ? (
          <ArticleSkeleton />
        ) : (
          <>
            {articleBody && title ? (
              <Box sx={style.articleBody} className="article__body">
                <Box
                  sx={hasSidebar ? style.content : { ...style.content, paddingRight: 0 }}
                  className="article__content"
                >
                  <h1>{title}</h1>
                  <Box sx={style.info} className="article__info">
                    <Box sx={style.date} className="article__date">
                      {dayjs(createdDate).utc(false).format("DD.MM.YYYY | HH:mm")}
                    </Box>
                    <Box sx={style.views} className="article__views">
                      <ViewIcon />
                      {countOfViews}
                    </Box>
                  </Box>
                  <div dangerouslySetInnerHTML={{ __html: articleBody }} />
                </Box>
                {hasSidebar ? (
                  <Box
                    component="aside"
                    ref={sidebarRef}
                    className={
                      !isSidebarOpen ? "article__sidebar" : "article__sidebar article__sidebar_open"
                    }
                    sx={!isSidebarOpen ? style.sidebar : [style.sidebar, style.sidebar.open]}
                  >
                    <Box sx={style.sidebar.inner} className="article__sidebar-inner">
                      {sidebar.new?.length ? (
                        <Box sx={style.widget} className="article__widget">
                          <Heading tag="h2" variant="h2">
                            {t("pages.app.article.sidebar.new")}
                          </Heading>
                          {sidebar.new?.length ? (
                            <Box component="ul">
                              {sidebar.new?.map((item) => (
                                <BlogCard key={item.id} data={item} />
                              ))}
                            </Box>
                          ) : null}
                        </Box>
                      ) : null}
                      {sidebar.popular?.length ? (
                        <Box sx={style.widget} className="article__widget">
                          <Heading tag="h2" variant="h2">
                            {t("pages.app.article.sidebar.popular")}
                          </Heading>
                          {sidebar.popular?.length ? (
                            <Box component="ul">
                              {sidebar.popular?.map((item) => (
                                <BlogCard key={item.id} data={item} />
                              ))}
                            </Box>
                          ) : null}
                        </Box>
                      ) : null}
                    </Box>
                    <Box
                      className={
                        !isSidebarOpen
                          ? "article__aside-toggler"
                          : "article__aside-toggler article__aside-toggler_open"
                      }
                      sx={
                        !isSidebarOpen
                          ? style.asideToggler
                          : [style.asideToggler, style.asideToggler.open]
                      }
                      onClick={handleSidebar}
                    >
                      <LeftIcon />
                    </Box>
                  </Box>
                ) : null}
              </Box>
            ) : (
              <Box sx={style.noData}>{t("pages.app.article.noData")}</Box>
            )}
            <Box sx={style.footer} className="article__footer">
              {previousArticleTag ? (
                <Box
                  sx={style.footer.prev}
                  component={Link}
                  to={`/${lang}${ROUTE.APP.BLOG}/${previousArticleTag}/`}
                >
                  <LeftIcon />
                  <span>{previousArticleTitle}</span>
                </Box>
              ) : (
                <Box />
              )}

              {nextArticleTag ? (
                <Box
                  sx={style.footer.next}
                  component={Link}
                  to={`/${lang}${ROUTE.APP.BLOG}/${nextArticleTag}/`}
                >
                  <span>{nextArticleTitle}</span>
                  <RightIcon />
                </Box>
              ) : (
                <Box />
              )}
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
};

Article.getServerSideState = async (store, params) => {
  const lang = params.locale;

  const tag = getBlogTagFromLocation(params?.location);

  await store.dispatch(getArticleByLangAndTag({ localeCode: lang, tag }));

  const state = await store.getState();

  const { article } = getBlogState(state);

  const loaded = Object.keys(article.content).length;

  if (loaded) {
    await store.dispatch(
      getArticlesSidebar({
        localeCode: lang,
        size: 2,
        articleTranslationId: article.content?.id
      })
    );
  }
};
export default Article;
