import { isSSR } from "./isSSR";

export const loadZohoChat = (lang) => {
  if (isSSR()) {
    return;
  }

  if (document.getElementById("zsiqscript")) {
    return;
  }

  window.$zoho = window.$zoho || {};
  window.$zoho.salesiq = window.$zoho.salesiq || {
    values: {},
    ready: function () {
      window.$zoho.salesiq?.chat?.waittime?.(1200);
      window.$zoho.salesiq.language(lang);
    }
  };

  const script = document.createElement("script");
  script.type = "text/javascript";
  script.id = "zsiqscript";
  script.src = `https://salesiq.zohopublic.eu/widget?wc=siqc184ab1f0d4ae595c0ae1d5535775efd5d3f4633c1cfdddddba6f13c43a7cbac`;
  script.defer = true;

  document.body.appendChild(script);
};
