export const getSearchParams = (location = "") => {
  if (typeof location !== "string") {
    throw new Error("Invalid location parameter");
  }

  return location
    .split("?")?.[1]
    ?.split("&")
    ?.reduce((acc, el) => {
      const [key, value] = el.split("=");

      return {
        ...acc,
        [key]: value
      };
    }, {});
};

export const getCountryFromPath = (location = "") => {
  if (typeof location !== "string") {
    throw new Error("Invalid location parameter");
  }

  const parts = location.split("/").filter(Boolean);
  return parts.length > 1 ? parts[1] : null;
};
export function getBlogTagFromLocation(location) {
  if (typeof location !== "string") {
    throw new Error("Invalid location parameter");
  }

  const blogIndex = location.indexOf("/blog/");

  if (blogIndex === -1) {
    return null;
  }

  const tag = location.substring(blogIndex + 6).split("/")[0];

  return tag || null;
}
