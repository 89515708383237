import { fluid } from "../../../../assets/styles/functions/fluid";
import { appTheme } from "../../../../theme";

export const style = {
  priceHero: {
    paddingTop: fluid(14, 12),
    paddingBottom: fluid(7, 4),
    wrapper: {
      "& > .heading": {
        marginBottom: "3.5rem",
        textAlign: "center"
      },

      "& > .tabs": {
        marginBottom: "1.6rem",

        [appTheme.breakpoints.down("bp992")]: {
          marginBottom: "2.5rem"
        },

        [appTheme.breakpoints.down("bp576")]: {
          marginBottom: "2rem"
        }
      }
    }
  },

  priceTable: {
    minWidth: "85rem",
    inner: {
      [appTheme.breakpoints.down("bp992")]: {
        overflow: "auto"
      }
    },

    head: {
      display: "grid",
      // gridTemplateColumns: 'minmax(22rem, 4fr) repeat(4, minmax(10rem, 3fr))',
      padding: "1.6rem 1.4rem",

      "& > .price-table__cell": {
        fontWeight: "600",
        color: "var(--clr-text-900)",

        "&:first-of-type": {
          paddingLeft: "0"
        },

        "&:last-child": {
          paddingRight: "0"
        }
      },

      [appTheme.breakpoints.down("bp992")]: {
        gridGap: "1.5rem"
        // gridTemplateColumns: 'minmax(9rem, 1.5fr) repeat(4, minmax(0, 1fr))',
      },

      [appTheme.breakpoints.down("bp767")]: {
        padding: "1rem"
      }
    },

    cell: {
      paddingRight: "1.5rem",
      paddingLeft: "1.5rem",
      fontSize: "1.8rem",
      textAlign: "center",

      leftMod: {
        textAlign: "left"
      },

      [appTheme.breakpoints.down("bp992")]: {
        paddingRight: "initial",
        paddingLeft: "initial"
      }
    },

    col: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      caption: {
        fontWeight: "400",
        fontSize: fluid(1.6, 1.2),
        color: "var(--clr-text-400)"
      },

      text: {
        marginRight: "0.5rem",
        fontWeight: "500",
        color: "var(--clr-text-500)",

        [appTheme.breakpoints.down("bp992")]: {
          fontSize: fluid(1.8, 1.4)
        }
      }
    },

    ".price-table__item + .price-table__item": {
      marginTop: "1.5rem",

      [appTheme.breakpoints.down("bp992")]: {
        marginTop: "1rem"
      }
    },

    link: {
      display: "grid",
      alignItems: "center",

      borderRadius: "4.5rem",
      padding: "1.4rem",
      boxShadow: "0px 10px 20px rgba(154, 162, 182, 0.12)",
      backgroundImage:
        "linear-gradient(96.2deg, #ffffff 0%, rgba(255, 255, 255, 0.75) 51.57%, #ffffff 100%)",
      transition: "box-shadow var(--transition)",

      [`@media (hover)`]: {
        "&:hover": {
          boxShadow: "0 2rem 2rem rgba(179 184 202 / 22%)",
          transition: "box-shadow var(--transition)"
        }
      },

      [appTheme.breakpoints.down("bp992")]: {
        boxShadow: "initial"
      },

      "& > .price-table__cell": {
        "&:first-of-type": {
          paddingLeft: "initial"
        },

        "&:last-child": {
          paddingRight: "initial"
        }
      },

      [appTheme.breakpoints.down("bp992")]: {
        gridGap: "1.5rem",
        // gridTemplateColumns: 'minmax(9rem, 1.5fr) repeat(4, minmax(0, 1fr))',
        borderRadius: "initial",
        boxShadow: "none"
      },

      [appTheme.breakpoints.down("bp767")]: {
        padding: "1rem"
      },

      [appTheme.breakpoints.down("bp370")]: {
        gridGap: "1rem"
      }
    },

    proxy: {
      display: "flex",
      alignItems: "center",

      image: {
        marginRight: "1.5rem",
        maxWidth: "4rem",
        maxHeight: "4rem",

        [appTheme.breakpoints.down("bp767")]: {
          maxWidth: "3rem"
        },

        img: {
          borderRadius: "50%",
          height: "auto",
          minHeight: "4rem",
          maxWidth: "100%",
          objectFit: "cover",

          [appTheme.breakpoints.down("bp767")]: {
            minHeight: "3rem"
          }
        }
      },

      text: {
        fontWeight: "600",
        fontSize: "2rem",
        color: "var(--clr-text-900)",

        [appTheme.breakpoints.down("bp992")]: {
          fontSize: fluid(1.8, 1.4)
        }
      }
    }
  },

  skeleton: {
    heading: {
      margin: "16px auto"
      // height: "53px"
    },

    item: {
      borderRadius: "4.5rem",
      height: "68px",
      marginTop: "1.5rem",
      [appTheme.breakpoints.down("bp767")]: {
        height: "55px",
        marginTop: "1rem"
      }
    }
  },

  tabsSkeleton: {
    borderRadius: "3rem",
    height: "52px",
    margin: "0 auto",
    marginBottom: "1.6rem",
    [appTheme.breakpoints.down("bp576")]: {
      height: "48px",
      maxWidth: "315px",
      width: "100%"
    }
  }
};
